import {stateRegistry} from 'microkernel';

export const STOCKCARS_VEHICLE_STORE_ID = 'dbadStockcarsVehicleStore';

class StockcarsVehicleStore {
	constructor() {
		if (!StockcarsVehicleStore.instance) {
			this._initStore();
			StockcarsVehicleStore.instance = this;
		}
		return StockcarsVehicleStore.instance;
	}

	_initStore() {
		const initialData = {
			vehicle: null
		};
		const actions = {
			update: this._update,
			invalidate: this._invalidate
		};
		stateRegistry.addStore(STOCKCARS_VEHICLE_STORE_ID, initialData, actions);
	}

	_update(state, params) {
		return {...state, vehicle: params.vehicle};
	}

	_invalidate(state) {
		return {...state, vehicle: null};
	}
}

const stockcarsVehicleStore = new StockcarsVehicleStore();
export {StockcarsVehicleStore, stockcarsVehicleStore};

Object.freeze(stockcarsVehicleStore);
